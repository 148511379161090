import React, { Component, createRef } from "react";
import logo from "../img/fox_t.png";

class Main extends Component {
    constructor(props) {
        super(props);
        this.textRef = createRef();
        this.textContentRef = createRef(); // Samostatný span pro text
        this.index = 0;
        this.texts = ["Lexikonn", "Fox Developer"];
        this.currentText = this.texts[this.index];
        this.isDeleting = true;
    }

    componentDidMount() {
        this.blinkBrackets();
        setTimeout(this.eraseText, 3000); // Po 3s začne mazání textu
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutId);
        clearInterval(this.blinkInterval);
    }

    blinkBrackets = () => {
        this.blinkInterval = setInterval(() => {
            if (this.textRef.current) {
                this.textRef.current.classList.toggle("hidden-brackets");
            }
        }, 500); // Blikání každých 500ms
    };

    eraseText = () => {
        if (this.currentText.length > 0) {
            this.currentText = this.currentText.slice(1, -1); // Mazání prvního a posledního znaku
            this.updateText();
            this.timeoutId = setTimeout(this.eraseText, 200);
        } else {
            this.isDeleting = false;
            this.index = (this.index + 1) % this.texts.length;
            setTimeout(this.typeText, 500);
        }
    };

    typeText = () => {
        const targetText = this.texts[this.index];
        if (this.currentText.length < targetText.length) {
            this.currentText = targetText.slice(0, this.currentText.length + 1);
            this.updateText();
            this.timeoutId = setTimeout(this.typeText, 200);
        } else {
            this.isDeleting = true;
            setTimeout(this.eraseText, 3000);
        }
    };

    updateText = () => {
        if (this.textContentRef.current) {
            this.textContentRef.current.innerText = this.currentText;
        }
    };

    render() {
        return (
            <div id="home" className="main text-center container-fluid">
                <div className="fw-bold logo oswald">
                    <img
                        style={{
                            maxHeight: "18rem",
                            pointerEvents: "none",
                            userSelect: "none"
                        }}
                        src={logo}
                        alt="logo"
                    />
                    <br />
                    <span id="lx">
                        <span className="brackets">{`{`}</span>&nbsp;
                        <span ref={this.textContentRef}>{this.currentText}</span>&nbsp;
                        <span className="brackets">{`}`}</span>
                    </span>
                </div>
            </div>
        );
    }
}

export default Main;
