import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhp, faReact, faJs, faNodeJs, faPython } from "@fortawesome/free-brands-svg-icons";

function iconName(iconName) {
    const icons = {
        php: { title: "PHP", color: "#8993be", icon: faPhp },
        react: { title: "React.js", color: "#61DBFB", icon: faReact },
        js: { title: "JavaScript", color: "#f0db4f", icon: faJs },
        node: { title: "Node.js", color: "#68a063", icon: faNodeJs },
        python: { title: "Python", color: "#FFDE57", icon: faPython },
    };

    const selectedIcon = icons[iconName];

    return selectedIcon ? (
        <FontAwesomeIcon title={selectedIcon.title} style={{ color: selectedIcon.color }} icon={selectedIcon.icon} />
    ) : null;
}

export default iconName;
