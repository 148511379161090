import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import iconName from "./icon.js";

const Projects = () => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        fetch("https://api.lexikonn.eu/main/getprojects.php")
            .then((response) => response.json())
            .then((data) => setProjects(data))
            .catch((error) => console.error("Chyba při načítání dat:", error));
    }, []);

    return (
        <Row className="align-items-center justify-content-center mt-4">
            {projects.map((p, index) => (
                <Col key={index} lg={6} md={12}>
                    <div className="project-card bg-dark text-light p-4 my-3 rounded" style={{ width: "100%" }}>
                        <h3>
                            <img
                                style={{ userSelect: "none", pointerEvents: "none" }}
                                className="pr-img"
                                src={p.icon !== "#" ? p.icon : "/favicon.ico"}
                                alt={p.name}
                            />&nbsp;
                            <strong>{p.name}</strong>
                        </h3>
                        <p className="text-light">{p.description}</p>
                        <p>
                            <a href={"https://" + p.url} target="_blank" rel="noopener noreferrer">
                                <i className="bi bi-globe2"></i> {p.url}
                            </a>
                        </p>
                        <p className="text-light fs-3">{iconName(p.type)}</p>
                    </div>
                </Col>
            ))}
        </Row>
    );
};

export default Projects;
