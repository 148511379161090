import React, {Component} from 'react';
import Projects from './Projects';

class About extends Component {
    render() {
        return (
            <div id="about" className="container-fluid text-center dark-section">
                <div className="text-center my-5">
                    <h1 className="display-4 fw-bold">O mně</h1>
                    <p className="fs-5 text-light mx-auto" style={{maxWidth: "800px"}}>
                        Jsem nadšený <span className="text-primary">fullstack programátor</span>, který s vášní tvoří
                        komplexní webové aplikace a kreativní projekty.
                        Programování je nejen mou prací, ale také koníčkem, který rád propojuji s <span
                        className="text-primary">3D grafikou</span>.
                        Díky tomu mám příležitost realizovat nápady, které spojují technické dovednosti s uměleckým
                        pohledem.
                    </p>
                </div>

                <div id="projects" className="my-5 container">
                    <h2 className="text-center fw-semibold">Mé realizované projekty</h2>
                    <Projects/>
                </div>

                <div className="text-center my-5">
                    <p className="fs-5 text-light mx-auto" style={{maxWidth: "800px"}}>
                    Mou silnou stránkou je schopnost vymýšlet a realizovat projekty od základů až po finální
                        produkt, a to s důrazem na detail a funkčnost.
                        Vždy hledám <span className="text-primary">originální řešení</span>, která osloví uživatele i
                        vývojáře.
                    </p>
                    <p className="fs-5 text-light mx-auto" style={{maxWidth: "800px"}}>
                        Miluji <span className="text-primary">výzvy</span> a neustále se učím nové technologie, které mě
                        posouvají kupředu.
                    </p>
                    <p className="fs-5 text-light mx-auto" style={{maxWidth: "800px"}}>
                        Mám bohaté zkušenosti s programováním v <span className="text-primary">PHP</span>, <span className="text-primary">JavaScriptu</span>, <span className="text-primary">React.js</span>, <span className="text-primary">Next.js</span> a <span className="text-primary">Pythonu</span>.
                        Umím pracovat s relačními databázemi (<span className="text-primary">SQL</span>) a vytvářet komplexní aplikace. Neustále se zdokonaluji v moderních technologiích a přístupech, abych mohl tvořit efektivní a inovativní řešení.
                    </p>
                </div>
            </div>
        );
    }
}

export default About;